<template>
  <div class="patentList fatherHeight contBox">
    <search-box>
      <template #top>
        <div class="search-top flex-between">
          <el-form :inline="true" :model="formData" ref="searchForm" class="demo-form-inline w-144">
            <el-form-item label>
              <el-input v-model="searchData.keyword" class="w-208" clearable placeholder="请输入搜索信息">
                <template #prefix>
                  <i class="fa fa-search"></i>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="透析频次：">
              <el-select v-model="searchData.dict_dialysis_cycle" clearable placeholder="请选择">
                <el-option label="全部" value></el-option>
                <el-option v-for="item in dictList[117000000]" :key="item.id" :label="item.name"
                  :value="item.code"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="长期/临时：">
              <el-select v-model="searchData.dict_dialysis_type" clearable placeholder="请选择">
                <el-option label="全部" value></el-option>
                <el-option v-for="item in dictList[210000000]" :key="item.id" :label="item.name"
                  :value="item.code"></el-option>
              </el-select>
            </el-form-item>
            
          </el-form>

          <div class="searchBut">
            <el-button class="color-main" @click="resetForm" type="primary">
              <i class="fa fa-refresh"></i>重置
            </el-button>
            <el-button @click="handleSearch" class="color-main" type="primary">
              <i class="fa fa-search"></i>查询
            </el-button>
          </div>
        </div>
      </template>
      <template #cont>
        <div class="tagBox">
          <div class="tit">标签筛选：</div>
          <div class="cont parient-tag">
            <el-tag :class="item.active ? 'active' : ''" v-for="item in dictList[323000000]" :key="item.id"
              @click="tagBut(item)">{{ item.name }}</el-tag>
          </div>
        </div>
      </template>
    </search-box>
    <div class="patientList borderImage">
      <div class="list-top flex-between">
        <div class="name">患者列表</div>
        <div class="item">
          <el-button @click="addParient" class="color-green" type="primary">
            <i class="fa fa-plus-circle"></i>新增
          </el-button>
          <!-- <el-button class="color-vice" type="primary">
            <i class="fa fa-arrow-up"></i>导入
          </el-button>
          <el-button class="color-vice" type="primary">
            <i class="fa fa-arrow-down"></i>导出
          </el-button> -->
        </div>
      </div>
      <el-alert class="patient-alert" :title="statistic" type="warning" :closable="false" show-icon></el-alert>
      <div class="table" ref="tableBoxRef">
        <el-table v-loading="dataListLoading" :data="tableData" class="table-ellipsis sss" v-if="istable" size="small"
          border :max-height="tableHei">
          <el-table-column fixed type="selection" align="center" width="42" />
          <!-- <el-table-column
            fixed
            prop="patient_id"
            label="序号"
            width="80"
            align="center"
          /> -->
          <el-table-column fixed sortable prop="name" label="姓名" width="100" />
          <el-table-column fixed sortable prop="case_no" label="病案号" width="120" />
          <el-table-column label="患者标签" width="232" show-overflow-tooltip>
            <template #default="scope">
              <el-tag class="table-tag" v-for="item in scope.row.patient_tag_list" :key="item.id">{{ item.name
                }}</el-tag>
              <!-- <el-tooltip
                placement="right"
                effect="light"
                v-if="scope.row.patient_tag_list.length > 2"
              >
                <template #content>
                  <el-tag
                    class="table-tag"
                    v-for="item in scope.row.patient_tag_list"
                    :key="item.id"
                    >{{ item.name }}</el-tag
                  >
                </template>
                <span class="fa-stack fa-lg">
                  <i class="fa fa-circle fa-stack-2x"></i>
                  <i class="fa fa-ellipsis-h fa-stack-1x"></i>
                </span>
              </el-tooltip>-->
            </template>
          </el-table-column>
          <el-table-column sortable prop="patient_source" label="来源" align="center" />
          <el-table-column sortable prop="created_time" width="180" label="建档时间" align="center" />
          <el-table-column sortable prop="updated_time" width="180" label="入室时间" align="center" />
          <el-table-column prop="age" label="年龄" />
          <el-table-column prop="mobile_phone" width="120" label="手机号" />
          <el-table-column prop="consultation_no" show-overflow-tooltip width="110" label="诊疗卡号" />
          <el-table-column width="150" label="病历填写情况" align="center">
            <template #default="scope">
              <el-tooltip class="box-item" effect="dark" content="方案" placement="top-start">
                <img v-if="scope.row.condition_arr.planCount" class="img" src="@/assets/img/ions/progress1.png" />
                <img v-else class="img" src="@/assets/img/ions/unprogress1.png" />
              </el-tooltip>
              <el-tooltip class="box-item" effect="dark" content="通路" placement="top-start">
                <img v-if="scope.row.condition_arr.tongluCount" class="img" src="@/assets/img/ions/progress2.png" />
                <img v-else class="img" src="@/assets/img/ions/unprogress2.png" />
              </el-tooltip>
              <el-tooltip class="box-item" effect="dark" content="医嘱" placement="top-start">
                <img v-if="scope.row.condition_arr.adviceCount" class="img" src="@/assets/img/ions/progress3.png" />
                <img v-else class="img" src="@/assets/img/ions/unprogress3.png" />
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="infect" width="120" label="感染状况" />
          <el-table-column width="90" label="医保类型">
            <template #default="scope">{{
              gitDictVal(dictList[103000000], scope.row.dict_payment_type)
              }}</template>
          </el-table-column>
          <el-table-column prop="duty_nurse_name" width="90" label="责任护士" />
          <el-table-column prop="duty_doctor_name" width="90" label="责任医生" />
          <el-table-column fixed="right" label="操作" width="160" align="center">
            <template #default="scope">
              <el-button type="text" size="small" @click="deatilsClick(scope.row)">
                <span class="txfonts modify">详</span>
              </el-button>
              <el-button type="text" size="small" @click="modParient(scope.row)">
                <span class="txfonts modify">改</span>
              </el-button>
              <el-button type="text" size="small" @click="delParient(scope.row)">
                <span class="txfonts del">删</span>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-box">
          <el-pagination :currentPage="pageData.currentPage" :page-size="pageData.pageSize"
            :page-sizes="[10, 20, 50, 100]" :background="true" layout="total, sizes, prev, pager, next, jumper"
            :total="pageData.totalSum" @size-change="handleSizeChange"
            @current-change="handleCurrentChange"></el-pagination>
        </div>
      </div>
    </div>
    <viewDialog v-if="isViewDialog" ref="viewDialogRef" @refreshDataList="getDataList()"></viewDialog>
  </div>
</template>
<script>
  import {
    reactive,
    toRefs,
    ref,
    nextTick,
    onMounted,
    onBeforeUnmount,
  } from 'vue'
  import { useRouter, useRoute } from 'vue-router'
  import service from '@/utils/request'
  import { useStore } from 'vuex'
  import searchBox from '@/components/search/searchBox.vue'
  import viewDialog from './viewDialog.vue'
  import { gitDictList, gitDictVal } from '@/utils/tool'
  import { ElMessageBox, ElMessage, ElNotification } from 'element-plus'
  export default {
    name: 'patentList',
    components: {
      searchBox,
      viewDialog,
    },
    setup() {
      const router = useRouter()
      const route = useRoute()
      const viewDialogRef = ref(null)
      const isViewDialog = ref(false)
      const state = reactive({
        searchData: {
          dict_dialysis_cycle: '',
          dict_dialysis_type: '',
          dict_hospital_area: '',
        },
        formData: {},
        pageData: {
          currentPage: 1,
          pageSize: 10,
          totalSum: 0,
        },
        dataListLoading: false,
        tableData: [],
        dictList: {},
        searchForm: null,
        statistic: '',
        istable: true,
        tableBoxRef: null,
        tableHei: 0,
        noticeList: [],
        notice: {
          max: 3,
          num: 0,
          index: 0,
        },
        noticeEm: {},
      })

      onMounted(() => {
        state.istable = false
        state.tableHei = state.tableBoxRef.clientHeight - 40
        nextTick(() => {
          state.istable = true
        })
        initData()
        getPatientCurrentNotice()
        window.noticeBtn = noticeBtn


      })
      onBeforeUnmount(() => {
        ElNotification.closeAll()
      })
      const getPatientCurrentNotice = async () => {
        let res = await service.post(
          '/api/patient_notice/get_patient_current_notice'
        )
        if (res.code === 0) {
          state.noticeList = res.data.list
          if (state.noticeList.length > 0) {
            addNotice()
          }
        }
      }
      const addNotice = () => {
        const row = state.noticeList[state.notice.index]
        state.noticeEm[row.id] = ElNotification({
          title: row.patient_name,
          dangerouslyUseHTMLString: true,
          // row.notice_content
          message: `<div class="notice_cont"><div>${row.notice_content}</div>
        <div class="flex-between">
          <div class="doctor">医生姓名：${row.doctor_name}</div>
          <div class="notice_but" onclick="noticeBtn(${row.id})">我知道了</div>
        </div>
        `,
          position: 'bottom-right',
          duration: 0,
          onClose: noticeClose,
        })
        state.notice.num++
        if (
          state.notice.num < state.notice.max &&
          state.notice.num < state.noticeList.length
        ) {
          state.notice.index++
          setTimeout(() => {
            addNotice()
          }, 800)
        }
      }
      const noticeClose = async () => {
        if (route.name != 'patientLists') return
        if (state.notice.index < state.noticeList.length - 1) {
          state.notice.index++
          setTimeout(() => {
            addNotice()
          }, 800)
        }
      }
      const noticeBtn = async (id) => {
        let res = await service.post('/api/patient_notice/close_notice', {
          id: id,
        })
        if (res.code === 0) {
          state.noticeEm[id].close()
        }
      }

      const handleSizeChange = (val) => {
        state.pageData.pageSize = val
        getDataList()
      }

      const handleCurrentChange = (val) => {
        state.pageData.currentPage = val
        getDataList()
      }

      const initData = async () => {
        handleSearch()
        state.dictList = await gitDictList([
          '117000000',
          '210000000',
          '103000000',
          '323000000',
        ])
        state.dictList[323000000].forEach((item) => {
          item.active = false
        })
      }

      const resetForm = () => {
        if (!state.searchForm) return
        state.searchData.keyword = ''
        state.searchForm.resetFields()
        handleSearch()
      }

      const handleSearch = () => {
        state.pageData.currentPage = 1
        getDataList()
      }

      const getDataList = async () => {
        state.searchData.current = state.pageData.currentPage
        state.searchData.size = state.pageData.pageSize
        let tabList = getTabList()
        state.searchData.tag_code = tabList
        state.dataListLoading = true
        let res = await service.post(
          '/api/patient/get_patient_list',
          state.searchData
        )
        state.dataListLoading = false
        if (res.code === 0) {
          state.tableData = res.data.records
          state.pageData.totalSum = res.data.total
          state.statistic = res.data.statistic
        }
      }

      const tagBut = (item) => {
        item.active = !item.active
      }
      // 获取选择标签
      const getTabList = () => {
        if (!state.dictList[323000000]) return
        let tabList = []
        const item = state.dictList[323000000]
        for (let i = 0; i < item.length; i++) {
          if (item[i].active) {
            tabList.push(item[i].code)
          }
        }
        return tabList
      }

      const addParient = () => {
        isViewDialog.value = true
        nextTick(() => {
          viewDialogRef.value.initData()
        })
      }

      const modParient = (item) => {
        isViewDialog.value = true
        nextTick(() => {
          viewDialogRef.value.initData(JSON.parse(JSON.stringify(item)))
        })
      }

      const delParient = (item) => {
        ElMessageBox.confirm('删除后无法撤销，确认删除吗？', '操作提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(async () => {
            let res = await service.post('/api/patient/delete_patient', {
              patient_id: item.patient_id,
            })
            if (res.code === 0) {
              ElMessage.success(res.msg)
              getDataList()
            }
          })
          .catch(() => { })
      }
      const store = useStore()
      console.log(store)
      const deatilsClick = async (item) => {
        store.dispatch("permission/generateRoutes", item.name);
        // store.commit('HzheFunction', item.name)
        // store.commit('permission/HzheFunctionName', item.name)
        let res = await service.post('/api/patient/get_patient_detail', {
          patient_id: item.patient_id,
        })
        if (res.code === 0) {
          // router.push({
          //   path: `/patients/patientDetails/${item.patient_id}`,
          // })
          router.push({
            path: `/patients/patientDetails/${item.patient_id}`,
            query: {
              name: item.name + '病历详情',
            }
          })
          // router.replace({
          //   path: `/patients/patientDetails/${item.patient_id}`,
          //   query: {
          //     name: item.name + '病历详情',
          //   }
          // })
        }
      }

      return {
        ...toRefs(state),

        addParient,
        modParient,
        delParient,
        isViewDialog,
        viewDialogRef,
        deatilsClick,
        handleSizeChange,
        handleCurrentChange,
        handleSearch,
        getDataList,
        resetForm,
        gitDictVal,
        tagBut,
        noticeBtn,
      }
    },
  }
</script>
<style lang="scss">
  .el-notification__group {
    flex: 1;
  }

  .notice_but {
    text-align: right;
    color: #3167a8;
    cursor: pointer;
  }
</style>
<style scoped lang="scss">
  .demo-form-inline {
    margin-top: 0;
    flex: 1;

    .el-form-item {
      margin-bottom: 0;
    }
  }

  .search-top .w-208 {
    width: 208px;
  }

  .table-tag {
    margin-right: 8px;
  }

  .fa-stack {
    width: 24px;
    height: 24px;
    cursor: pointer;
    float: right;

    .fa-stack-2x {
      font-size: 24px;
      color: rgba(49, 102, 174, 0.1);
    }

    .fa-stack-1x {
      font-size: 12px;
      color: #3166ae;
      line-height: 24px;
    }
  }

  .tagBox {
    display: flex;
    justify-content: flex-start;

    // margin-bottom: 10px;
    .tit {
      width: 83px;
      font-size: 14px;
      font-family: 'Source Han Sans CN-Bold';
      font-weight: bold;
      color: #666666;
    }

    .cont {
      width: calc(100% - 83px);
    }
  }

  .patientList {
    margin-top: 4px;
    height: calc(100% - 45px);
  }

  .list-top {
    .name {
      font-size: 18px;
      font-family: ' Source Han Sans CN-Medium';
      font-weight: 500;
      color: #000000;
      line-height: 32px;
    }
  }

  .patient-alert {
    background: rgba(49, 102, 174, 0.1);
    border-radius: 4px 4px 4px 4px;
    font-size: 18px;
    color: #3166ae;
    margin-top: 8px;
    padding: 4px 16px;

    .el-alert__icon {
      color: #3166ae;
    }

    &:deep(.el-alert__content) {
      position: relative;
      top: 3px;
    }
  }

  .table {
    margin-top: 10px;
    height: calc(100% - 82px);

    .img {
      width: 25px;
      height: 24px;
      margin-right: 14px;
    }
  }

  .searchBut {
    width: 200px;
    margin-left: 20px;
  }

  .del {
    background-color: #ffecec;
    color: #ff6b6b;
    font-weight: 700;
  }

  .modify {
    background-color: #3166ae;
  }
</style>