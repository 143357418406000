<template>
  <div class="searchBox">
    <el-collapse v-model="activeCollNames" accordion>
      <el-collapse-item name="1" disabled>
        <template #title>
          <div class="searchTop">
            <slot name="top" />
            <div class="cuttle" @click="cuttleBut">
              {{ !activeCollNames ? '展开' : '收起' }}
            </div>
          </div>
        </template>
        <div class="cont">
          <slot name="cont" />
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script>
import { ref } from 'vue'
export default {
  name: 'patentList',
  setup() {
    const activeCollNames = ref('')

    const cuttleBut = () => {
      if (activeCollNames.value) {
        activeCollNames.value = ''
      } else {
        activeCollNames.value = '1'
      }
    }

    return {
      activeCollNames,
      cuttleBut,
    }
  },
}
</script>

<style scoped lang="scss">
.w-208 {
  width: 208px;
}
.searchBox {
  &:deep(.el-collapse) {
    border: 0;
    .el-collapse-item__header {
      border: 0;
      cursor: default;
      align-items: flex-start;
      height: 40px;
      line-height: 32px;
    }
    .el-collapse-item__content {
      padding-bottom: 0;
    }
  }
  .searchTop {
    width: 100%;
    position: relative;
    padding-right: 44px;
    .cuttle {
      position: absolute;
      right: 4px;
      top: 10px;
    }
  }
}
.cont {
  margin-top: 4px;
}
</style>
